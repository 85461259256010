<style>
  .title {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  .place {
    font-size: 20px;
  }
  .address {
    font-size: 16px;
  }
  .grid {
    display: flex;
  }
  .entry {
    flex-basis: 33.333%;
    flex: 0 0 33.333333%;
  }
  .holder {
    margin-top: 20vh;
  }
  span {
    display: block;
  }
  @media only screen and (max-width: 1024px) {
    .holder {
      margin-top: 10vh;
    }
    .title {
      margin-bottom: 0.25rem;
    }
    .grid {
      flex-direction: column;
      align-items: center;
    }
    .entry,
    a {
      flex-basis: 100%;
      flex: 0 0 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .entry {
      margin-bottom: 48px;
    }
  }
</style>

<div class="holder">
  <div class="grid">
    <div class="entry">
      <span class="title">Ceremony</span>
      <span class="place">Hidden Barn Venue</span>
      <a
        noreferrer
        noopener
        target="_blank"
        href="https://www.google.com/maps/place/Hidden+Barn+Venue/@28.7756517,-81.5793647,17z/data=!3m1!4b1!4m5!3m4!1s0x88e79f6695cfa0ad:0x85d1e1c729672175!8m2!3d28.775647!4d-81.577176">
        <span class="address">4251 Ondich Road</span>
        <span class="address">Apopka, FL 32712</span>
      </a>
    </div>

    <div class="entry">
      <span class="title">Lodging near the venue</span>
      <span class="place">Hilton Garden Inn Apopka</span>
      <a
        noreferrer
        noopener
        target="_blank"
        href="https://www.google.com/maps/place/Hilton+Garden+Inn+Apopka+City+Center/@28.6712588,-81.50187,17z/data=!3m1!4b1!4m8!3m7!1s0x88e77612192cc1df:0x16e104cb26336216!5m2!4m1!1i2!8m2!3d28.6712541!4d-81.499676">
        <span class="address">580 E Main St</span>
        <span class="address">Apopka, FL 32703</span>
      </a>
    </div>

    <div class="entry">
      <span class="title">Hotels closer to Orlando</span>
      <span class="place">Many Options</span>
      <a
        noreferrer
        noopener
        target="_blank"
        href="https://www.travelocity.com/International-Drive-Hotels.d6034244.Travel-Guide-Hotels">
        <span class="address">Circa International Drive</span>
        <span class="address">Orlando, FL 32819</span>
      </a>
    </div>
  </div>
</div>
